import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server

//获取企业列表
export function corporationList(page, size, cpName = '') {
  return request({
    url: `${server}/auth/corporationManage/corporationList?pageIndex=${page}&pageSize=${size}&cpName=${cpName}`,
    method: 'get'
  })
}

//新建企业列表
export function insertCorporationInfo(data) {
  return request({
    url: `${server}/auth/corporationManage/insertCorporationInfo`,
    method: 'post',
    data
  })
}

//更新企业列表信息
export function updateCorporationInfo(data) {
  return request({
    url: `${server}/auth/corporationManage/updateCorporationInfo`,
    method: 'post',
    data
  })
}

//设置企业状体
export function enterpriseSetInvalid(cpId, status) {
  return request({
    url: `${server}/auth/corporationManage/enterpriseSetInvalid?cpId=${cpId}&status=${status}`,
    method: 'get'
  })
}

//访客绑定到企业
export function setupRoleInEnterprise(data) {
  return request({
    url: `${server}/auth/corporationManage/BindEnterprises`,
    method: 'post',
    data
  })
}

//生成企业邀请码
export function generateCpInvitationCode(cpId = '') {
  return request({
    url: `${server}/auth/corporationManage/generateCpInvitationCode?cpId=${cpId}`,
    method: 'get',
  })
}

//获取企业邀请码
export function queryEnterpriseInvitationCode(cpId = '') {
  return request({
    url: `${server}/auth/corporationManage/queryEnterpriseInvitationCode?cpId=${cpId}`,
    method: 'get'
  })
}

//修改企业邀请码
export function updateEnterpriseInvitationCode(data) {
  return request({
    url: `${server}/auth/corporationManage/updateEnterpriseInvitationCode`,
    method: 'post',
    data
  })
}

export function updateCorporationTrial({ cpId, isTrial, effectiveDate }) {
  return request({
    url: `${server}/auth/corporationManage/updateCorporationTrial`,
    method: 'post',
    data: {
      cpId, isTrial, effectiveDate
    }
  })
}



