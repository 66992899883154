<template>
  <el-card class="enterprise-page page-container">
    <el-form slot="header" size="small" :model="enterpriseInfo" class="enterprise-information" :inline="true">
      <el-form-item label="企业名称">
        <el-input v-model="enterpriseInfo.name" style="width: 200px" @blur="searchEnterise(enterpriseInfo.name)"></el-input>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="small" type="primary" @click="newEnterprise">新增企业</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="enterpriseList" :height="tHeight" :key="refreshKey">
      <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
      <el-table-column label="编号" prop="cpId" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="名称" :show-overflow-tooltip="true" width="200">
        <template slot-scope="scope">
          <el-link type="primary" @click="handleShowEnterprise(scope.row)">{{ scope.row.cpName }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="联系电话" prop="cpPhone" width="120"></el-table-column>
      <el-table-column label="公司地址" prop="cpAddress" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="状态" prop="status" width="80" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status ? 'primary' : 'danger'" size="mini">{{ scope.row.status ? '生效' : '失效' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="类型" prop="status" width="80" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isTrial ? 'danger' : 'primary'" size="mini">{{ scope.row.isTrial ? '试用' : '永久' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="系统名称" prop="systemName" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="系统图标" align="center">
        <template slot-scope="scope">
          <el-image :src="scope.row.systemIcon" style="width: 40px" :key="refreshKey"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="createUserName" width="120"></el-table-column>
      <el-table-column label="到期时间" align="center" width="140">
        <template slot-scope="scope">
          {{ scope.row.isTrial === 1 ? $moment(scope.row.effectiveDate).format('YYYY-MM-DD HH:mm') : '----' }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="140">
        <template slot-scope="scope">
          {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="handleChangeServeConfig(scope.row)">系统配置</el-button>
          <el-button type="text" :style="{ color: scope.row.status ? '#F40' : '#67C23A' }" @click="enterpriseSetInvalid(scope.row)">{{ scope.row.status ? '失效' : '生效' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" title="企业信息" :visible="dialogVisible" :before-close="handleClose">
      <el-form ref="EnterpriseForm" label-position="top" :rules="rules" :model="enterpriseDetails">
        <el-form-item label="企业名称" prop="cpName">
          <el-input v-model="enterpriseDetails.cpName"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="cpPhone">
          <el-input v-model="enterpriseDetails.cpPhone"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="cpAddress">
          <el-input v-model="enterpriseDetails.cpAddress"></el-input>
        </el-form-item>
      </el-form>
      <el-row slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button size="small" type="primary" @click="submit('EnterpriseForm')">确认</el-button>
      </el-row>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible="isEditServerConfig" @close="handleCloseServerConfig">
      <el-form label-width="80px" label-position="left" v-model="serverConfig">
        <el-form-item label="系统名称">
          <el-input v-model="serverConfig.name"></el-input>
        </el-form-item>
        <el-form-item label="企业图标">
          <form>
            <div class="form-file-upload-wrap">
              <label for="logoUpload">
                <img v-if="serverConfig.logo" :src="serverConfig.logo" class="logo" style="width: 60px" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </label>
              <input id="logoUpload" type="file" style="display: none" ref="upLoadFile" @change="beforFileLoad" />
            </div>
          </form>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="submitServerConfig">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-drawer title="企业信息" :visible.sync="drawer" :direction="direction" @open="handleOpenDrawer" :before-close="handleCloseDrawer">
      <ul class="enterprise-details-wrapper">
        <li class="enterprise-item" v-if="false">
          <label>企业编号：</label>
          <span>{{ showEnterpriseDetails.cpId }}</span>
          <el-button type="text" icon="el-icon-s-order" v-clipboard:copy="showEnterpriseDetails.cpId" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError">复制</el-button>
        </li>
        <li class="enterprise-item">
          <label>企业名称：</label>
          <span>{{ showEnterpriseDetails.cpName }}</span>
        </li>
        <li class="enterprise-item">
          <label>信用编码：</label>
          <span>{{ showEnterpriseDetails.cpNum }}</span>
        </li>
        <li class="enterprise-item">
          <label>地址：</label>
          <span>{{ showEnterpriseDetails.cpAddress }}</span>
        </li>
        <li class="enterprise-item">
          <label>联系电话：</label>
          <span>{{ showEnterpriseDetails.cpPhone }}</span>
        </li>
        <li class="enterprise-item">
          <label>创建人编号：</label>
          <span>{{ showEnterpriseDetails.createUserId }}</span>
        </li>
        <li class="enterprise-item">
          <label>创建人名称：</label>
          <span>{{ showEnterpriseDetails.createUserName }}</span>
        </li>
        <li class="enterprise-item">
          <label>创建时间：</label>
          <span>{{ $moment(showEnterpriseDetails.createTime).format('YYYY-MM-DD') }}</span>
        </li>
        <li class="enterprise-item">
          <label>更新人编码：</label>
          <span>{{ showEnterpriseDetails.updateUserId }}</span>
        </li>
        <li class="enterprise-item">
          <label>更新人名称：</label>
          <span>{{ showEnterpriseDetails.updateUserName }}</span>
        </li>
        <li class="enterprise-item">
          <label>更新时间：</label>
          <span>{{ showEnterpriseDetails.updateTime }}</span>
        </li>
        <li class="enterprise-item">
          <label>企业状态：</label>
          <span>{{ showEnterpriseDetails.status ? '生效' : '失效' }}</span>
        </li>
      </ul>
      <el-form size="mini" label-position="left" ref="ApprovalForm" :hide-required-asterisk="true" :rules="approvalInfoRules" :model="approval" label-width="100px">
        <div class="enterprise-wrapper" v-if="approval.inviteCode">
          <el-form-item label="企业编号">
            <el-input v-model="showEnterpriseDetails.cpId" style="width: 248px"></el-input>
          </el-form-item>
          <el-form-item label="企业邀请码" prop="inviteCode">
            <el-input v-model="approval.inviteCode" style="width: 248px">
              <el-button slot="append" icon="el-icon-s-order" v-clipboard:copy="templateText" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError">
                <el-tooltip class="item" effect="dark" content="复制企业编号" placement="top-start">
                  <span>复制</span>
                </el-tooltip>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="失效时间" prop="failureTime">
            <el-date-picker style="width: 248px" v-model="approval.failureTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" @click="handleChangeInvitecode" style="width: 248px" type="primary">修改企业邀请码</el-button>
          </el-form-item>
        </div>
        <div class="created-invite-code" v-else>
          <el-form-item>
            <el-button size="mini" type="primary" style="width: 248px">生成企业邀请码</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-form size="mini" label-position="left" label-width="100px" ref="CorporationValidity" :model="showEnterpriseDetails">
        <el-form-item label="试用">
          <el-radio v-model="showEnterpriseDetails.isTrial" :label="0">永久</el-radio>
          <el-radio v-model="showEnterpriseDetails.isTrial" :label="1">试用</el-radio>
        </el-form-item>
        <el-form-item label="过期时间" v-if="showEnterpriseDetails.isTrial !== 0">
          <el-date-picker style="width: 248px" v-model="showEnterpriseDetails.effectiveDate" type="date" placeholder="Pick a day"> </el-date-picker>
          <!-- <el-date-picker style="width: 248px" v-model="showEnterpriseDetails.effectiveDate" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd"></el-date-picker> -->
        </el-form-item>
        <el-form-item>
          <el-button size="mini" style="width: 248px" type="primary" @click="updateCorporationValidity('CorporationValidity')">更新企业有限期</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>
import PaginationBar from '@/components/Pagination/index.vue';
import sleep from '@/utils/sleep';
import { corporationList, insertCorporationInfo, updateCorporationInfo, enterpriseSetInvalid, generateCpInvitationCode, queryEnterpriseInvitationCode, updateEnterpriseInvitationCode, updateCorporationTrial } from '@/api/enterprise.js';
import statusInclude from '@/utils/statusCode';
import { getToken, getAuthorization } from '@/utils/auth';
import { checkContact, isUSCC, uInviteCode } from '@/utils/rules.js';
import { base_url } from '@/utils/BASE_URL.js';
import axios from 'axios';
export default {
  name: 'Enterprise',
  components: {
    PaginationBar,
  },
  data() {
    return {
      tHeight: 0,

      direction: 'rtl',
      templateText: '',
      dialogVisible: false,
      isEditServerConfig: false,
      isEdit: false,
      drawer: false,
      refreshKey: 'el-table',
      enterpriseList: [],
      showEnterpriseDetails: {},
      enterpriseInfo: {
        name: '',
      },
      enterpriseDetails: {
        cpName: '',
        cpPhone: '',
        cpAddress: '',
        cpNum: '',
      },
      serverConfig: {
        cpId: '',
        name: '',
        logo: '',
      },
      approval: {
        inviteCode: '',
        failureTime: '',
      },
      rules: {
        cpName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        cpPhone: [{ required: true, validator: checkContact, trigger: 'blur' }],
        cpAddress: [{ required: true, message: '请输入公司地址', trigger: 'blur' }],
        cpNum: [{ required: true, validator: isUSCC, trigger: 'blur' }],
      },
      approvalInfoRules: {
        inviteCode: [{ required: true, validator: uInviteCode, trigger: 'blur' }],
        failureTime: { type: 'date', required: true, message: '选择日期时间', trigger: 'change' },
      },
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
      corporationAttr: {
        cpId: '',
        isTrial: 0,
        effectiveDate: '',
      },
      corporationAttrRule: {
        effectiveDate: { type: 'date', required: true, message: '选择日期时间', trigger: 'change' },
      },
    };
  },
  created() {
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
    // this.templateText = `企业编码：${}`
  },
  mounted() {
    this.getCorporationList({ ...this.tableConfig });
  },

  methods: {
    //复制到剪切板成功
    handleCopySuccess(e) {
      this.$message.success('复制成功!');
    },
    //复制到剪切板失败
    handleCopyError(e) {
      this.$message.error('复制失败!');
    },
    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },
    newEnterprise() {
      this.isEdit = false;
      this.dialogVisible = true;
    },
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getCorporationList({ ...this.tableConfig });
    },
    //查找企业信息
    searchEnterise(name) {
      this.getCorporationList({ ...this.tableConfig, name });
    },
    //查看企业详细信息
    handleShowEnterprise(data) {
      this.showEnterpriseDetails = {};
      this.showEnterpriseDetails = data;
      this.drawer = true;
    },
    handleEdit(info) {
      this.enterpriseDetails = JSON.parse(JSON.stringify(info));
      this.isEdit = true;
      this.dialogVisible = true;
    },

    handleChangeServeConfig(row) {
      this.serverConfig.cpId = row.cpId;
      this.serverConfig.logo = row.systemIcon;
      this.serverConfig.name = row.systemName;
      this.isEditServerConfig = true;
    },

    //hide dialog
    handleClose() {
      this.dialogVisible = false;
      this.enterpriseDetails = {};
      this.resetForm();
    },
    //打开抽屉
    handleOpenDrawer() {
      this.$nextTick(() => {
        this.generateCpInvitationCode(this.showEnterpriseDetails.cpId);
      });
    },
    //关闭抽屉
    handleCloseDrawer() {
      this.drawer = false;
    },

    //关闭系统设置dialog
    handleCloseServerConfig() {
      this.serverConfig = {
        name: '',
        cpId: '',
        logo: '',
      };
      setTimeout(() => {
        this.getCorporationList({ ...this.tableConfig });
      }, 5000);
      this.isEditServerConfig = false;
    },

    beforFileLoad() {
      const {
        files: [logo],
      } = this.$refs.upLoadFile;
      this.logoFile = logo;
      const uploadAccept = ['jpeg', 'jpg', 'png']; //上传图片允许的格式
      const text = logo.type.split('/')[1]; //后缀名
      if (!uploadAccept.includes(text)) return this.$message.warning('您上传的图片格式不符，请重新上传');
      const reader = new FileReader();
      reader.readAsDataURL(logo);
      reader.onload = () => {
        this.serverConfig.logo = reader.result; //base64
      };
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { code, reason } = this.isEdit ? await updateCorporationInfo(this.enterpriseDetails) : await insertCorporationInfo(this.enterpriseDetails);
          if (!statusInclude(code)) return this.$message.error(reason);
          this.$message({ type: 'success', message: '请求成功' });
          this.getCorporationList({ ...this.tableConfig });
          this.handleClose();
        } else {
          throw new Error('information is mising');
        }
      });
    },
    submitServerConfig() {
      const Authorization = getAuthorization() || '';
      const refreshToken = getToken() || '';
      const headers = { Authorization, refreshToken, 'Content-Type': 'multipart/form-data' };
      const formData = new FormData();
      formData.append('file', this.logoFile);
      formData.append('systemName', this.serverConfig.name);
      formData.append('cpId', this.serverConfig.cpId);
      axios({
        method: 'post',
        url: `${base_url.server}/auth/authService/customizeSystemInfo`,
        data: formData,
        headers,
      })
        .then((response) => {
          if (!statusInclude(response.data.code)) return this.$message.error(`系统配置更新失败：${response.data.reason}`);
          this.$message.success('系统配置成功');
          this.isEditServerConfig = false;
          location.reload();
        })
        .catch((error) => {
          this.$message.error('系统配置失败');
          throw new Error(error);
        });
    },
    resetForm() {
      this.$refs.EnterpriseForm.resetFields();
    },

    // 更新企业账号有效期
    updateCorporationValidity(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid)
          return updateCorporationTrial({ ...this.showEnterpriseDetails }).then((response) => {
            if (statusInclude(response.code)) return this.$message.success(response.reason);
            return this.$message.error(response.reason);
          });
      });
    },

    //修改企业邀请码
    async handleChangeInvitecode() {
      const params = JSON.parse(JSON.stringify(this.approval));
      params.cpId = this.showEnterpriseDetails.cpId;
      const { code, data, reason } = await updateEnterpriseInvitationCode(params);
      if (!statusInclude(code)) return this.$message.error(`修改失败：${reason}`);
      this.$message({ type: 'success', message: '修改成功' });
      this.handleCloseDrawer();
    },
    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },

    async getCorporationList({ page, size, name }) {
      const { code, reason, data } = await corporationList(page, size, name);
      if (!statusInclude(code)) return this.$message.error(reason);
      const { records, total } = data;
      this.enterpriseList = records.reduce((per, cur) => {
        const tmp = JSON.parse(JSON.stringify(cur));
        tmp.systemIcon = cur.systemIcon + '?' + Math.random() * 1000;
        per.push(tmp);
        return per;
      }, []);
      this.tableConfig.total = total;
      this.refreshKey = new Date().getTime() + 'el-table';
    },
    //设置企业状态
    async enterpriseSetInvalid(data) {
      const { cpId, status } = data;
      try {
        const { code, reason } = await enterpriseSetInvalid(cpId, status === 1 ? 0 : 1);
        this.$message({ type: statusInclude(code) ? 'success' : 'danger', message: reason });
        this.getCorporationList({ ...this.tableConfig });
      } catch (error) {
        throw new Error(`SetInvalid Function Has Error:${error}`);
      }
    },
    //生成企业邀请码
    async generateCpInvitationCode(cpId) {
      const { code, reason, data } = await generateCpInvitationCode(cpId);
      if (!statusInclude(code)) return this.$message.error(`生成企业邀请码失败：${reason}`);
      this.templateText = `企业编号：${cpId}\n\n企业邀请码：${data.inviteCode}`;
      this.approval.inviteCode = data.inviteCode;
      this.approval.failureTime = data.failureTime;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
