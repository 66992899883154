//校验账号
export const checkUserName = (rule, value, cb) => {
  const reg = /^[a-zA-Z0-9-_]{3,15}$/;
  if (!value) {
    cb(new Error('请输入用户名'));
  } else if (reg.test(value)) {
    cb();
  } else {
    cb(new Error('请输入不包含特殊字符的3-15位的合法用户名'));
  }
};

export function checkAccount(rule, value, cb) {
  const rules = {
    account: /^[a-zA-Z0-9-_]{3,15}$/,
    mobile: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
  };
  if (!value) {
    cb(new Error('请输入账号或手机号'));
  } else if (rules.account.test(value) || rules.mobile.test(value)) {
    return cb();
  } else {
    cb(new Error('请输入正确的账号或手机号'));
  }
}

//校验密码
export function checkPassword(rule, value, cb) {
  const reg = /^[\w]{6,16}$/;
  if (!value) return cb('请输入密码');
  if (reg.test(value) && value.indexOf('@') === -1) return cb();
  return cb('6~16位，包含大小写字母和数字的组合');
}

//校验邮箱
export function checkEmail(rule, value, cb) {
  const reg = /^([a-zA-Z0-9_\.-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
  if (!value) {
    return cb(new Error('请输入合法的邮箱'));
  } else if (reg.test(value)) {
    return cb();
  } else {
    return cb(new Error('请输入合法的邮箱'));
  }
}

// 验证手机号
export function checkMobile(rule, value, cb) {
  const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法的手机号'));
}

/**
 * @description: 校验机库编号
 * @param rule
 * @param value
 * @param cb
 * @return {*}
 */
export function examineHiveID(rule, value, cb) {
  const HIVEModels = [3, 4, 5, 'M'];
  const reg = new RegExp(`^HIVE_((UH(([${HIVEModels}])|(3P)|(4P)|(5P)))|(ZY1))_000[0-9]{3}$`);
  if (!value) {
    return cb();
  } else if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法机库编号,例：HIVE_UH4_000001'));
}

/**
 * @description: 校验无人机编号
 * @param rule
 * @param value
 * @param cb
 * @return {*}
 */
export function examineDroneId(rule, value, cb) {
  const reg = /^UAV_((M210V2)|(M300)|(MP)|(P4P))_000[0-9]{3}$/;
  if (!value) {
    return cb();
  } else if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法无人机编号,例：UAV_M300_000001'));
}

/**
 * @description: 检测机身号是否正确
 * @param {*} rule
 * @param {*} value
 * @param {*} cb
 * @return {*}
 */
export function examineSN(rule, value, cb) {
  const reg = /^[A-Z0-9]{13,30}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法SN编号'));
}

//校验姓名是否合法
export function checkName(rule, value, cb) {
  const reg = /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]){2,4}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入真实姓名'));
}

//手机验证码是否合法
export function isVerifyCode(rule, value, cb) {
  var reg = /^[0-9]{6}$/;
  if (reg.test(value)) {
    return cb();
  } else {
    cb(new Error('请输入合法验证码'));
  }
}

//校验用户名是否合法
export function checkStr(rule, value, cb) {
  var reg = /^[a-zA-Z0-9-_]{4,16}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法用户名'));
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

//校验地址坐标是否合法
export function isLnglat(rule, value, cb) {
  let lng, lat;
  if (value.split(',').length == 2) {
    let arr = value.split(',');
    (lng = arr[0]), (lat = arr[1]);
  } else if (value.split('，').length == 2) {
    let arr = value.split('，');
    (lng = arr[0]), (lat = arr[1]);
  } else {
    cb(new Error('坐标例:121.3245671,31.123456'));
    return false;
  }
  const lngReg = /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10})$/;
  const latReg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/;
  if (lngReg.test(lng) && latReg.test(lat)) {
    cb();
    return true;
  }
  cb(new Error('坐标例:121.3245671,31.123456'));
  return false;
}

export function isLnglatByValue(value) {
  let lng, lat;
  if (value.split(',').length == 2) {
    let arr = value.split(',');
    (lng = arr[0]), (lat = arr[1]);
  } else if (value.split('，').length == 2) {
    let arr = value.split(',');
    (lng = arr[0]), (lat = arr[1]);
  } else {
    return false;
  }
  const lngReg = /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10})$/;
  const latReg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/;
  if (lngReg.test(lng) && latReg.test(lat)) {
    return true;
  }
  return false;
}
export function isSiteId(rule, value, cb) {
  const reg = /^ST_[0-9]{8}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('编号例：ST_00000001'));
}

export function isMissionName(rule, value, cb) {
  const reg = /[a-zA-Z0-9-\u4E00-\u9FFF_-]{5,26}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法任务名称'));
}

export function ilsite(rule, value, cb) {
  const reg = /^[a-zA-Z,0-9,\u4E00-\uFA29,_,#]{4,16}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法站点名称（4-15个字符）'));
}

export function hoverAltitude(rule, value, cb) {
  if (value >= 5) {
    return cb();
  }
  cb(new Error('返航悬停高度至少5米'));
}

export function isFLoat(rule, value, cb) {
  value = value.toString();
  value = value.replace(' ', '');
  if (value.trim() != '' && !isNaN(Number(value, 10))) {
    return cb();
  }
  cb(new Error('无效的数字'));
}

export function isFLoatByValue(value) {
  value = value.toString();
  value = value.replace(' ', '');
  if (value.trim() != '' && !isNaN(Number(value, 10))) {
    return true;
  }
  return true;
}

export function examineBatterySNSN(rule, value, cb) {
  const reg = /^BAT_((TB60)|(TB55)|(PH4))_[0-9]{6}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法电池SN编号'));
}

export function isUSCC(rule, value, cb) {
  const reg = /^(([0-9A-Za-z]{15})|([0-9A-Za-z]{18})|([0-9A-Za-z]{20}))$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('请输入合法统一社会信用代码'));
}

export function checkContact(rule, value, cb) {
  const reg = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/;
  const mobileReg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
  if (value === '') return cb(new Error('请输入联系方式'));
  if (value.length === 11) {
    if (!mobileReg.test(value)) return cb(new Error('手机号码格式错误'));
    cb();
  } else if (reg.test(value)) {
    cb();
  } else {
    cb(new Error('电话号码格式错误'));
  }
}

export function uInviteCode(rule, value, cb) {
  const reg = /^[0-9A-Za-z]{6}$/;
  if (reg.test(value)) {
    return cb();
  }
  cb(new Error('例：6AC123'));
}
